@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    height: 6.6rem;

    input::placeholder {
        color: gray;
    }

    .input {
        border: 1px solid white;
    }

    .container {
        margin: unset;
    }

    &.disabled {
        color: grey;
        .input {
            color: grey;
            border: 1px solid grey;
            cursor: not-allowed;
            pointer-events: none;
        }
        & > *:hover {
            cursor: not-allowed;
        }
    }
}

.labelWrapper {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.exclamationIcon {
    height: 1.5rem;
    svg {
        width: 1.5rem;
    }
}

.error {
    color: red;
}
