@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.toggleWrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .toggle {
        height: 2.5rem;
    }
}
